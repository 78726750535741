/*styling for the Main game board*/
.board {
    border: 10px solid gray;
    background-color:  rgb(41, 175, 41);
    text-align: center;
    position: relative;
    width: 45vw;
    height: 45vw;
    margin: auto;
  }

/*style for the info board*/
.game-info{
  float: left;
  width: 15%;
}

/*style for the game board*/
.game{
  float: left;
  width: 50%;
}

/*style for the chat boxes*/
.chat{
  float: right;
  width: 33%;
}

/*all squares in the board except the board*/
.squares {
    background-color:transparent;
    height: 20%;
    width: 20%;
    max-height: 100vw;
    max-width: 100vw;
    position: relative;
    float: left;
    padding: 0;
    margin: auto;
  }

  .squares>  img {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

/*the main outline in the illustration: is also a square*/
  .inner-square {
    background-color:transparent;
    height: 80%;
    width: 80%;
    position: absolute;
    top: 10%;
    left: 10%;
    pointer-events: none;
  }

/*smaller square illustration in the inner-square: also a square: type backslash*/
  .outline1{
    height: 25%;
    width: 25%;
    border: yellow;
    border-style: dashed;
    position: relative;
    pointer-events: none;
  }

  .outline1::after{
    content:"";
    position:absolute;
    border-top:1px solid red;
    width:141.5%;
    transform: 
        translate(-36%)
        rotate(45deg);
    transform-origin: 0% 0%;
    pointer-events: none;
  }

/*smaller square illustration in the inner-square: also a square: type frontslash*/
  .outline2{
    height: 25%;
    width: 25%;
    border: yellow;
    border-style: dashed;
    position: relative;
    pointer-events: none;
  }

  .outline2::after{
    content:"";
    position:absolute;
    border-top:1px solid yellow;
    width:141.5%;
    transform: 
        translate(36%)
        rotate(135deg);
    transform-origin: 0% 0%;
    pointer-events: none;
  }

  @media only screen and (max-width: 600px) {
    /* For mobile phones: */
    [class="board"] {
      width: 100vw;
      height: 100vw;
    }
    [class="game"]{
      /* display: block; */
      width: 100%;
    }
    [class="chat"]{
      /* display: block; */
      width: 100%;
    }
    [class="game-info"]{
      /* display: block; */
      width: 100%;
    }
  }

